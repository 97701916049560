import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import MakeItRain from './MakeItRain';
import logo from './logo.svg';
import styles from './App.module.css';
import uncutFunk from './images/uncut_funk.jpg';
import discordUwu from './images/discord_uwu.png';
import power from './images/power.png';
import {useScrollPosition} from '@n8tb1t/use-scroll-position';
import satLogo from './images/sat_logo.png';
import mtggroup from './images/mtggroup.png';
import YouTube from 'react-youtube';
import stonks from './images/stonks.png';
import trenchcoat from './images/trenchcoat.png';
import boot from './images/boot.png';
import knife from './images/knife.png';
import cider from './images/cider.png';
import satbook from './images/satbook.png';

const musics = ['VZvifPawrOw', 'HS4NqNkhTOo', 'Xl1lS6B9pMc', '9KqIqVLABgo', 'oWQ3MW2IVCw', 'sdQqgVzex_w'];

const generateUwus = () => {
  const bounds = [100, 200];
  const uwuCount = _.range(Math.ceil(Math.random() * 20));
  const uwus = uwuCount.map(uwuNumber => {
    const ehe = [Math.ceil(Math.random() * bounds[0]), Math.ceil(Math.random() * bounds[1])];
    return ehe;
  });
  return uwus;
};

const App: React.FC = () => {
  const videoId = React.useMemo(() => _.sample(musics), []);
  const uwus = React.useMemo(generateUwus, []);
  const randomScrollPoint = React.useMemo(() => Math.floor(Math.random() * 1000) + 600, []);
  const [makeItRainSeen, setMakeItRainSeen] = React.useState(false);

  useScrollPosition(({currPos}) => {
    if (currPos.y >= randomScrollPoint && !makeItRainSeen) {
      setMakeItRainSeen(true);
    }
  });

  return (
    <div className={styles.page}>
      <img src={discordUwu} className={styles.discordUwu} />
      <div className={styles.uncutFunk}>
        <div className={styles.impeterwu}>
          <span style={{color: '#00FF47'}} className={styles.text}>
            herro
          </span>
          <span style={{color: '#2BA431'}} className={styles.text}>
            i'm peter
            <span className={styles.blinkingUwu}>uWu</span>
          </span>
          <div className={styles.uwuContainer}>
            {uwus.map(uwuBounds => (
              <span className={classnames(styles.uwu, styles.text)} style={{left: uwuBounds[0], top: uwuBounds[1]}}>
                uwu
              </span>
            ))}
          </div>
        </div>
        <img src={uncutFunk} />
      </div>
      <div className={styles.power}>
        <div className={styles.powerBoxes} style={{width: '1280px', height: '100%'}}></div>
        <div className={styles.powerTextContainer}>
          <div className={styles.powerBox1} />
          <div className={styles.powerBox2} />
          <div className={styles.powerBox3} />
          <span className={classnames(styles.hasText, styles.text)}>I has the ultimates</span>
          <span className={classnames(styles.powerText, styles.text)}>
            POWERPOWERPOWERPENISPOWERPOWERPOWERPOWERPENISPOWER
          </span>
          <span className={classnames(styles.rberkeley)}>r/berkeley mothafucka</span>
          <img src={power} className={styles.powerImg} />
        </div>
      </div>
      <div className={styles.sat}>
        <img src={satLogo} className={styles.satLogo} />
        <span className={styles.fullScore}>2400</span>
        <span className={styles.achieve}>I achieved more than anyone</span>
        <span className={styles.math}>Math</span>
        <span className={styles.reading}>Reading</span>
        <span className={styles.writing}>Writing</span>
        <span className={styles.math800}>800</span>
        <span className={styles.reading800}>800</span>
        <span className={styles.writing800}>800</span>
        <span className={styles.gre}>
          and a full scroe in &nbsp; <span className={styles.gregre}>GRE</span>
        </span>
      </div>
      <div className={styles.mtg}>
        <img src={mtggroup} />
      </div>
      <div className={styles.stonks}>
        <div className={styles.player}>
          <YouTube
            videoId={videoId}
            opts={{
              width: '650',
              height: '367',
              playerVars: {
                autoplay: 1,
              },
            }}
          />
          <div className={styles.unpausable} onClick={() => alert('you tryna deny my sadboi musics?')} />
        </div>
        <img src={stonks} className={styles.stonkImage} />
        <span className={styles.indie}>They call me an edgy indie boy</span>
      </div>
      <div className={styles.starterPack}>
        <hr className={styles.stars} />
        <span className={classnames(styles.starterPackText, styles.text)}>peter uWu starter pack</span>
        <div className={styles.starterPackItems}>
          <img src={boot} className={classnames(styles.starterPackImage, styles.boot)} />
          <img src={trenchcoat} className={classnames(styles.starterPackImage, styles.trenchcoat)} />
          <img src={knife} className={classnames(styles.starterPackImage, styles.knife)} />
          <img src={cider} className={classnames(styles.starterPackImage, styles.cider)} />
          <img src={satbook} className={classnames(styles.starterPackImage, styles.satbook)} />
        </div>
        <hr className={styles.stars} />
      </div>
      <footer className={styles.footer}>
        Designed by <a href={'//designbyroka.com'}>Roka</a> and coded by Jonz
      </footer>
      {/* <MakeItRain duration={3} /> */}
    </div>
  );
};

export default App;
